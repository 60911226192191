@import '../../variables.module.scss';

.callToAction{
  background-color: $initial_button_primary_color !important;
  border-color: $initial_button_primary_color !important;
}

.headingServices{

  font-family: 'BlackJack';
  color: #6a2478;
  font-size: 1.6em;
  margin-bottom: 10px;
}

.servicesDescription{

  font-family: Raleway, sans-serif;
  font-size: 1em;
  line-height: 26px;
  color: #818181 !important;
  font-weight: 300;
  overflow-x: hidden !important;
  -webkit-font-smoothing: antialiased;
  padding: 20px 50px 20px 50px;
  // text-align: justify;
}
.fragmetServices{

  padding-bottom:50px;
}