.teste {
  background:  black; //works
  height: 80vh;
  .swiper-button-prev.swiper-button-disabled{
    color: green !important
  }
  
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;

  /* Center slide text vertically */
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}

.swiper-slide img {
  display: block;
  width: 100vw;
  height: 100%;
  object-fit: cover;

  //Wenn man ddas bild noch etwas verschieben will
  // margin-top: -120px;
}

.video{
  width: 100vw;
  height: 100%;
  object-fit: cover;
}

//  https://alvarotrigo.com/blog/css-text-animations/
.swiper-slide-inner-container {
  // width: 235px;
	position: absolute;
	top: 35%;
	// left: 50%;
	z-index: 10000;
}

.overlay{
	// padding: 0 70px;
	box-sizing: border-box;
	position: absolute;
  max-width: 95%;
	top: 50%;
	left: 50%;
	-webkit-transform: translate(-50%,-50%);
	transform: translate(-50%,-50%);

  display: flex;
  flex-direction: column;
  align-items: center;
}

.headingOne {
  color: #333;
  font-family: tahoma;
  font-size: 3rem;
  font-weight: 100;
  line-height: 1.5;
  text-transform: uppercase;
  white-space: nowrap;
  overflow: hidden;
  position: relative;
  width: 800px;
}

.headingOne span {
  // font-size: 50px;
  position: relative;
}
.headingTwo span {
  font-size: 2rem;
  position: relative;
}
.callToActionButton {
  font-size: 1.5rem;
}

h
.message {
  background-color: yellow;
  color: #333;
  display: block;
  font-weight: 900;
  overflow: hidden;
  position: absolute;
  padding-left: 0.5rem;
  top: 0.2rem;
  left: 514px;
  // animation: opencloseOneWord 10s ease-in-out infinite;
  animation: opencloseThreeWords 10s ease-in-out infinite;
}

// .word1, .word2, .word3 {
//   font-family: tahoma;
// }
.word1 {
  font-family: tahoma;
}

@keyframes opencloseThreeWords {
  0% {
    top: 0.2rem;
    width: 0;
  }
  5% {
    width: 0;
  }
  15% {
    width: 230px;
  }
  30% {
    top: 0.2rem;
    width: 230px;
  }
  33% {
    top: 0.2rem;
    width: 0;
  }
  35% {
    top: 0.2rem;
    width: 0;
  }
  38% {
    top: -4.5rem;
    
  }
  48% {
    top: -4.5rem;
    width: 190px;
  }
  62% {
    top: -4.5rem;
    width: 190px;
  }
  66% {
    top: -4.5rem;
    width: 0;
    text-indent: 0;
  }
  71% {
    top: -9rem;
    width: 0;
    text-indent: 5px;
  }
  86% {
    top: -9rem;
    width: 285px;
  }
  95% {
    top: -9rem;
    width: 300px;
  }
  98% {
    top: -9rem;
    width: 0;
    text-indent: 5px;
  }
  100% {
    top: 0;
    width: 0;
    text-indent: 0;
  }
}


@keyframes opencloseOneWord {
  0% {
    top: 0.2rem;
    width: 0;
  }
  5% {
    width: 0;
  }
  15% {
    width: 230px;
  }
  98% {
    width: 230px;
  }
  100% {
    top: 0;
    width: 0;
    text-indent: 0;
  }
}


// Animation Scale
@keyframes scale {
  100% {
    transform: scale(1);
  }
}